<template>
  <div>
    <div class="subNav wid">
      <div class="subTypeName">Contact us</div>
      <div class="subTypeName tt">联系我们</div>

      <div class="clr"></div>
      <center>
        <dl>

        </dl>
      </center>
      <div style="background:#c6c6c6;margin:20px 0 10px;width:100%;height:1px"></div>
    </div>

    <div class="contact wid" id="clear">
      <div><b>公司号码：</b>{{ telephone}} 传真：{{ fax }}<br>
        <strong>公司地址：</strong>{{ address }}&nbsp;<br>
        <strong>公司邮箱：</strong><a :href="'mailto:' + email ">{{ email }}</a><br>
        <strong>分公司：</strong>{{ branchOffice }}</div>
    </div>

    <div class="lineBox company">
      <center><iframe id="ifr" name="ifr" frameborder="no" scrolling="no" width="1200" height="600" src="https://j.map.baidu.com/88/XPd"></iframe></center>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      telephone: '',
      fax: '',
      address: '',
      email: '',
      branchOffice: '',
    }
  },
  mounted() {
    this.getPublicData()
  },
  methods: {
    getPublicData() {
      this.$api.getPublicData({name: '工科院基本信息'}).then(res => {
        const data = res.data
        this.telephone = data.gong_ke_yuan_you_xian ? data.gong_ke_yuan_you_xian.value : ''
        this.fax = data.gong_ke_yuan_chuan_zheng ? data.gong_ke_yuan_chuan_zheng.value : ''
        this.address = data.gong_ke_yuan_di_zhi ? data.gong_ke_yuan_di_zhi.value : ''
        this.email = data.gong_ke_yuan_you_xian ? data.gong_ke_yuan_you_xian.value : ''
        this.branchOffice = data.gong_ke_yuan_fen_gong_si ? data.gong_ke_yuan_fen_gong_si.value : ''
      })
    },
  }
}
</script>
